<router>
{
    name: 'Analytics Settings',
}
</router>

<template lang="pug">
v-container(light)
    v-row
        v-col(cols="12")
            SystemsSettings(
                :systems="localSystems",
                :customerId="customerId",
                :uom="uom",
                @update:systems="handleUpdateSystems",
                @save:systems="handleSaveSystems",
                @refresh="fetchConfig",
                @create:model="handleCreateModel",
                @show-snackbar="$root.$emit('show:alert', $event)"
            )

</template>

<script lang="js">

import mergeDeep from 'lodash/merge';
import SystemsSettings from '@/components/portal/analytics/Systems';

import { mapActions, mapGetters } from 'vuex';
import dashboard from '@/components/dashboard.vue';

export default {
    name: 'AnalyticsSettings',
    extends: dashboard,
    components: {
        SystemsSettings,
    },
    data () {
        return {
            selectedSystem: null,
            localSystems: [],
            uom: [],
            customerId: 0,
        };
    },
    methods: {
        ...mapActions([
            'fetchAnalyticsConfig',
            'saveAnalyticsConfig',
            'fetchAnalyticsUnitsOfMeasure',
            'updateAnalyticsConfig',
            'createAnalyticsModel',
        ]),

        // Update the systems configuration in the store, be sure to always pass the entire systems array
        handleUpdateSystems (newSystems) {
            console.log('Updating systems:', newSystems);
            this.updateAnalyticsConfig(newSystems);
        },

        async fetchConfig () {
            try {
                await Promise.all([
                    this.fetchAnalyticsConfig(),
                    this.fetchAnalyticsUnitsOfMeasure()
                ]);
                this.$set(this, 'localSystems', mergeDeep([], this.analyticsConfig));
                this.$set(this, 'uom', mergeDeep([], this.analyticsUnitsOfMeasure));
            } catch (error) {
                console.error('Error fetching configuration', error);
                this.showSnackbar({message: 'Error fetching systems', type: 'error'});
            }
        },

        showSnackbar ({message, type = null}) {
            this.$root.$emit('show:alert', {message, type});
        },

        // Save the systems configuration to server
        async handleSaveSystems (newSystems) {
            try {
                console.log('settings.vue >> Saving systems:', newSystems);
                await this.saveAnalyticsConfig(newSystems);

                if(this.error){
                    console.error('Error saving configuration', this.errorMessage);
                    this.showSnackbar({message: 'Error saving systems', type: 'error'});
                    return;
                }

                await this.fetchConfig();
                if(this.error){
                    console.error('Error fetching configuration', this.errorMessage);
                    this.showSnackbar({message: 'Error fetching systems', type: 'error'});
                    return;
                }

                this.showSnackbar({message: 'Systems saved successfully'});
            } catch (error) {
                console.error('Error saving configuration', error);
                this.showSnackbar({message: 'Error saving systems', type: 'error'});
            }
        },

        /**
         *
         * @param {object} categoryPayload
         * @param {object} categoryPayload.system_id - The system to save the categories for
          * @param {array} categoryPayload.categories - The categories to save
         */
        async handleSaveCategories (categoryPayload) {
            console.log('Saving categories:', categoryPayload);
            // find the system in the config
            const found = this.analyticsConfig.find(system => system.id === this.selectedSystem.id);
            if (!found) {
                console.error('Could not find system in config');
                return;
            }
            found.categories = categoryPayload.categories;

            this.setAnalyticsConfig(this.analyticsConfig);

        },

        /**
         *
         * @param {string} systemId
         */
        async handleCreateModel (systemId) {
            console.log('Creating model:', systemId);
            await this.createAnalyticsModel(systemId);
            if(this.error){
                console.error('Error creating model', this.errorMessage);
                this.showSnackbar({message: 'Error creating model', type: 'error'});
                return;
            }
            if(this.taskModelId){
                this.showSnackbar({message: 'Model Creation Queued'});
            }
        },

        openPoints (system) {
            this.$set(this, 'selectedSystem', system);
            this.$set(this, 'uom', this.analyticsUnitsOfMeasure);
            this.$set(this, 'canViewPoints', true);
        },

        downloadingClient (bool) {
            console.log('settings >> Downloading client:', bool);
            this.isDownloadingClient = bool;
        }
    },
    watch: {
        customerId: {
            handler (newVal) {
                console.log('Customer ID changed:', newVal);
                this.fetchConfig();
                this.canViewPoints = false;
            },
            immediate: true
        }
    },
    computed: {
        ...mapGetters([
            'error',
            'errorMessage',
            'analyticsUnitsOfMeasure',
            'analyticsConfig',
            'taskModelId'
        ]),
    },
    created () {
        this.$store.dispatch('connectSocket');
        this.fetchConfig();
    },
    mounted () {
        window.settings = this;
        console.log('this.$store:', this.$store);
    }
};

</script>
