<template lang="pug">
v-container
    v-card(light)
        v-card-title Systems
        v-card-text
            v-row
                v-col(cols="12")
                    v-data-table(
                        :headers="headers"
                        :items="localSystems"
                        class="elevation-1"
                        item-key="id"
                        light
                    )

                        template(v-slot:item.system_name="{ item }")
                            span.align-center {{ item.system_name }}
                                v-icon(:color="areAllCategoriesFinalized(item) ? 'green' : 'red'" class="ml-3")
                                    | {{ areAllCategoriesFinalized(item) ? 'check' : 'close' }}


                        template(v-slot:item.actions="{ item }")
                            v-btn(v-if="!deleteEnabled[item.id]" color="grey" @click="enableDelete(item.id)").mr-3 Enable Delete
                            v-tooltip(top v-if="deleteEnabled[item.id]")
                                template(v-slot:activator="{ on, attrs }")
                                    v-btn(
                                        @click="deleteSystem(item.id)"
                                        color="red"
                                        class="mr-3"
                                        v-bind="attrs"
                                        v-on="on"
                                    ) Delete
                                span Delete System (all data will be removed)
                            v-tooltip(top)
                                template(v-slot:activator="{ on, attrs }")
                                    v-btn(
                                        @click="openEditDialog(item)"
                                        color="orange"
                                        class="mr-3"
                                        v-bind="attrs"
                                        v-on="on"
                                    ) Edit
                                span Edit System Parameters
                            v-tooltip(top)
                                template(v-slot:activator="{ on, attrs }")
                                    v-btn(
                                        @click="openCategories(item)"
                                        color="#00ffff"
                                        :disabled="item.categories.length === 0"
                                        class="mr-3"
                                        v-bind="attrs"
                                        v-on="on"
                                    ) Configure
                                span Configure Categories and Points
                            v-tooltip(top)
                                template(v-slot:activator="{ on, attrs }")
                                    v-btn(
                                        color="brand"
                                        @click="handleDownloadClient(item.id)"
                                        class="mr-3 white--text"
                                        bind="attrs"
                                        v-on="on"
                                    ) Download Client
                                span Download Client Installer
                            v-tooltip(top v-if="areAllCategoriesFinalized(item)" )
                                template(v-slot:activator="{ on, attrs }")
                                    v-btn(
                                        color="#AF0D0F"
                                        @click="createModel(item)"
                                        class="mr-3 white--text"
                                        bind="attrs"
                                        v-on="on"
                                    ) Create model
                                span Create a new model for this system (may take an hour or more)

            v-row(v-if="showCategories")
                v-col(cols="12" )
                    Categories(
                        :categories="selectedSystem?.categories",
                        :systemName="selectedSystem?.system_name",
                        :systemId="selectedSystem?.id",
                        :uom="uom",
                        @close:categories="showCategories = false",
                        @update:categories="handleUpdateCategories"
                    )
        v-card-actions
            v-tooltip(top)
                template(v-slot:activator="{ on, attrs }")
                    v-btn(color="green" @click="checkForDataLoss" v-bind="attrs" v-on="on") Save All Systems
                span Sends all system changes to Server
            v-btn(
                @click="addSystem"
                :disabled="isDownloading || isAddSystemsDisabled"
                color="orange"
                class="ml-2"
            ) Add System
            v-tooltip(top)
                template(v-slot:activator="{ on, attrs }")
                    v-btn(
                        @click="refresh"
                        color="blue"
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                    ) Refresh
                span Reset Changes
            v-tooltip(top)
                template(v-slot:activator="{ on, attrs }")
                    v-btn(
                        @click="goToAnalyticsHome"
                        v-bind="attrs"
                        v-on="on"
                        class="ml-2"
                    ) Analytics Home
                span Go To Advanced Analytics
            v-tooltip(top)
                template(v-slot:activator="{ on, attrs }")
                    v-btn(
                        @click="goToHelp"
                        v-bind="attrs"
                        v-on="on"
                        class="ml-2"
                        color="brand white--text"
                    ) Help
                span Go To Help
            v-spacer
            span.no-changes-text No changes sent to server without clicking <b>Save All Systems</b>

    v-dialog(v-model="editDialog" max-width="600px")
        v-card(light)
            v-card-title
                v-row(align="center" justify="space-between")
                    span {{ dialogTitle }}
                    v-btn(icon @click="closeEditDialog")
                        v-icon close
            v-card-text
                v-form(ref="editForm", v-model="formValid")
                    v-row()
                        v-col(cols="12")
                            v-text-field(
                                v-model.trim="editedItem.system_name"
                                label="System Name"
                                :rules="[rules.required]"
                                outlined
                                dense
                                persistent-hint
                                color="brand"
                            )
                    v-row()
                        v-col(cols="12")
                            v-text-field(
                                v-model.trim="editedItem.api_key"
                                :label="softwareType"
                                :rules="[rules.required]"
                                outlined
                                dense
                                persistent-hint
                                color="brand"
                            )
                    v-row()
                        v-col(cols="6")
                            v-text-field(
                                v-model.number="editedItem.latitude"
                                label="Latitude"
                                :rules="[rules.isNumber, rules.latitude]"
                                outlined
                                dense
                                type="number"
                                step="0.0001"
                                persistent-hint
                                color="brand"
                            )
                        v-col(cols="6")
                            v-text-field(
                                v-model.number="editedItem.longitude"
                                label="Longitude"
                                :rules="[rules.isNumber, rules.longitude]"
                                outlined
                                dense
                                type="number"
                                step="0.0001"
                                persistent-hint
                                color="brand"
                            )
                    v-row()
                        v-col(cols="12")
                            v-select(
                                v-model="editedItem.timezone"
                                :items="timeZones"
                                label="Time Zone"
                                :rules="[rules.required]"
                                outlined
                                dense
                                persistent-hint
                                color="brand"
                            )
                    v-row()
                        v-col(cols="6")
                            v-text-field(
                                v-model.number="editedItem.flow_production_max"
                                label="Production Max"
                                :rules="[rules.isNumber, rules.productionMax]"
                                outlined
                                dense
                                type="number"
                                step="1"
                                persistent-hint
                                color="brand"
                            )
                        v-col(cols="6")
                            v-select(
                                v-model="editedItem.flow_production_uom"
                                :items="productionUomOptions"
                                item-text="name"
                                item-value="value"
                                label="Production UOM"
                                outlined
                                dense
                                persistent-hint
                                color="brand"
                            )
                    v-row()
                        v-col(cols="12" sm="6" md="4")
                            v-text-field(
                                v-model.number="editedItem.flow_storage_max"
                                label="Storage Max"
                                :rules="[rules.isNumber, rules.storageMax, rules.storageMaxMin]"
                                outlined
                                dense
                                type="number"
                                step="1"
                                persistent-hint
                                color="brand"
                            )
                        v-col(cols="12" sm="6" md="4")
                            v-text-field(
                                v-model.number="editedItem.flow_storage_min"
                                label="Storage Min"
                                :rules="[rules.isNumber, rules.storageMin, rules.storageMinMax]"
                                outlined
                                dense
                                type="number"
                                step="1"
                                persistent-hint
                                color="brand"
                            )
                        v-col(cols="12" sm="6" md="4")
                            v-select(
                                v-model="editedItem.flow_storage_uom"
                                :items="storageUomOptions"
                                item-text="name"
                                item-value="value"
                                label="Storage UOM"
                                outlined
                                dense
                                persistent-hint
                                color="brand"
                            )
                    v-row()
                        v-col(cols="6")
                            v-text-field(
                                v-model="editedItem.flow_plant_start_time"
                                label="Plant Start Time (HH:mm)"
                                :rules="[rules.required, rules.plantStartTime]"
                                outlined
                                dense
                                persistent-hint
                                color="brand"
                                class="no-clipping"
                            )
                        v-col(cols="6")
                            v-text-field(
                                v-model="editedItem.id"
                                label="system id (read-only)"
                                readonly
                                outlined
                                dense
                                color="brand"
                            )
            v-card-actions
                v-spacer
                v-btn(color="green" :disabled="!formValid" @click="saveEdit") Save
                v-btn(color="grey" @click="closeEditDialog") Cancel

    v-dialog(v-model="showWarningDialog", persistent, max-width="500")
        v-card(light)
            v-card
                v-card-title.headline Data Loss Warning
                v-card-text
                    p Making these changes will result in data loss and require a new model to be created.  Are you sure you want to continue?
                    ul.change-list
                        li(
                            v-for="(change, field) in pendingChanges"
                            :key="field"
                        )
                            div {{ formatFieldName(field) }}
                                .change-details
                                    span From: {{ change.from || 'empty' }}
                                    span.mx-2 →
                                    span To: {{ change.to || 'empty' }}

                v-card-actions
                    v-btn(
                        color="red darken-1"
                        @click="proceedWithChanges"
                    ) I Know What I'm Doing
                    v-spacer
                    v-btn(
                        color="grey"
                        @click="cancelChanges"
                    ) Cancel


    v-dialog(v-model='isDownloading', persistent, max-width='500')
        v-card(light)
            v-card-title
                p {{clientInstallerState}}
            v-card-text
                v-row
                    v-col(cols='12')
                        v-progress-linear(:value='percentDone', color='brand', striped, height='24')
                            template(v-slot:default='{value}')
                                strong.white--text {{ Math.ceil(value) }}%


    v-dialog(v-model="showDataLossDialog", persistent, max-width="500")
        v-card(light)
            v-card-title Data Loss Warning
            v-card-text
                p  Making these changes will result in data loss and require a new model to be created.  Are you sure you want to continue?
                v-simple-table
                    template(v-slot:default)
                        thead
                            tr
                                th System Name
                                th Flow Data
                                th Weather Data
                        tbody
                            tr(v-for="system in systemsDataLoss")
                                td {{ system.name }}
                                td {{ system['flow'] ? 'X' : '' }}
                                td {{ system['weather'] ? 'X' : '' }}
            v-card-actions
                v-btn(color="red", @click="saveAllSystems", class="white--text") I Know What I'm Doing
                v-spacer
                v-btn(color="grey", @click="showDataLossDialog = false") Cancel

    v-snackbar(
        v-model="snackbar"
        :timeout="timeout"
        :color="color"
        :multi-line="multiLine"
    )
        span(:style="{ color: textColor }") {{ message }}

        template(v-slot:action="{ attrs }")
            v-btn(text v-bind="attrs" @click="snackbar = false") Close
</template>

<script>

import {nanoid} from 'nanoid';
import mergeDeep from 'lodash/merge';
import Categories from './Categories.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'SystemsSettings',
    emits: ['update:systems', 'refresh', 'save:systems', 'download:client', 'create:model'],
    components: {
        Categories
    },
    props: {
        customerId: {
            type: Number,
            required: true
        },
        systems: {
            type: Array,
            default: () => []
        },
        uom: {
            type: Array,
            required: true
        },
    },
    data () {
        return {
            deleteEnabled: {},
            isDownloading: false,
            selectedSystem: null,
            showCategories: false,
            percentDone: 0,
            clientInstallerState: '',
            showDataLossDialog: false,
            localUom: [],
            headers: [
                { text: 'Name', value: 'system_name' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            rules: {
                required: (value) => !!value || 'Required.',
                isNumber: (value) => !isNaN(parseFloat(value)) || 'Must be a number.',
                latitude: (value) => value >= -90 && value <= 90 || 'Must be between -90 and 90.',
                longitude: (value) => value >= -180 && value <= 180 || 'Must be between -180 and 180.',
                productionMax: (value) => value >= 0 && value <= 100 || 'Must be between 0 and 100.',
                storageMax: (value) => value >= 0 && value <= 100 || 'Must be between 0 and 100.',
                storageMin: (value) => value >= 0 && value <= 100 || 'Must be between 0 and 100.',
                plantStartTime: (value) => /^([01]?\d|2[0-3]):[0-5]\d$/.test(value) || 'Must be a valid time in HH:mm format.',
                storageMaxMin: (value) => value >= this.editedItem.flow_storage_min || 'Storage Max must be greater than Storage Min.',
                storageMinMax: (value) => value <= this.editedItem.flow_storage_max || 'Storage Min must be less than Storage Max.',
            },
            timeZones: [
                'America/New_York',    // Eastern Time
                'America/Detroit',     // Eastern Time
                'America/Kentucky/Louisville', // Eastern Time
                'America/Kentucky/Monticello', // Eastern Time
                'America/Indiana/Indianapolis', // Eastern Time
                'America/Indiana/Vincennes', // Eastern Time
                'America/Indiana/Winamac', // Eastern Time
                'America/Indiana/Marengo', // Eastern Time
                'America/Indiana/Petersburg', // Eastern Time
                'America/Indiana/Vevay', // Eastern Time
                'America/Chicago',     // Central Time
                'America/Indiana/Tell_City', // Central Time
                'America/Indiana/Knox', // Central Time
                'America/Menominee', // Central Time
                'America/North_Dakota/Center', // Central Time
                'America/North_Dakota/New_Salem', // Central Time
                'America/North_Dakota/Beulah', // Central Time
                'America/Denver',      // Mountain Time
                'America/Boise',       // Mountain Time
                'America/Phoenix',     // Mountain Standard Time (no DST)
                'America/Los_Angeles', // Pacific Time
                'America/Anchorage',   // Alaska Time
                'America/Juneau',      // Alaska Time
                'America/Sitka',       // Alaska Time
                'America/Metlakatla',  // Alaska Time
                'America/Yakutat',     // Alaska Time
                'America/Nome',        // Alaska Time
                'America/Adak'         // Hawaii-Aleutian Time
            ],
            // populate from API
            storageUomOptions: [
                { name: 'Gallons', value: 'G' },
                { name: 'Million Gallons', value: 'MG' },
            ],
            productionUomOptions: [
                { name: 'Gallons per Day', value: 'GPD' },
                { name: 'Million Gallons per Day', value: 'MGPD' },
            ],
            localSystems: mergeDeep([], this.systems),
            defaultItem: {
                id: null,
                system_name: '',
                latitude: null,
                longitude: null,
                flow_production_max: null,
                flow_production_uom: 'GPD',
                flow_storage_max: null,
                flow_storage_min: null,
                flow_storage_uom: 'G',
                flow_plant_start_time: '12:00',
                customerId: this.customerId,
                api_key: '',
                categories: [],
                timezone: '',
                isDrastic: false
            },
            editDialog: false,
            editedItem: { ...this.defaultItem },
            newItem: null,
            formValid: false,

            // dangerous changes dialog
            showWarningDialog: false,
            pendingChanges: null,
            progressColor: 'orange',

            // snackbar
            alertMessage: '',
            snackbar: false,
            message: "",
            color: "brand",
            textColor: "#ffffff",
            timeout: 3000,
            multiLine: false,

            rotateValue: 0,
        };
    },
    computed: {
        dialogTitle () {
            return this.newItem ? 'Add System' : 'Edit System';
        },
        isAddSystemsDisabled () {
            return this.localSystems.length > 0;
        },
        softwareType () {
            const software = this.customerData.customersConfig.systemSoftware === 'Other' ?  'server' : this.customerData.customersConfig.systemSoftware;
            return `API Key for talking to customer's ${software}`;
        },
        ...mapGetters([
            'customerData',
            'systemsDataLoss',
            'error',
            'errorMessage'
        ])
    },
    methods: {
        ...mapActions ([
            'generateClientFile',
            'checkForSystemsDataLoss'
        ]),

        splitPath (filePath) {
            // Detect the separator used in the path string
            const separator = filePath.includes('\\') ? '\\' : '/';
            return filePath.split(separator);
        },

        /**
         *
         * @param {object} options
         * @param {string} options.message - The message to display in the snackbar
         * @param {string} options.color - default is red
         * @param {number} options.timeout - default is 3000 milliseconds
         */
        showSnackbar ({message, color = "brand", textColor = "#ffffff", timeout = 3000, type = null}) {
            if (type === 'warning') {
                color = 'orange';
                textColor = '#000000';
            } else if (type === 'error') {
                color = '#8B0000';
                textColor = '#ffffff';
            }
            this.message = message;
            this.color = color;
            this.textColor = textColor;
            this.timeout = timeout;
            this.snackbar = true;
        },

        async handleDownloadClient (systemId) {
            await this.$nextTick();


            this.isDownloading = true;

            this.percentDone = 0;

            const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

            // Start a timer to increment percentDone
            const interval = setInterval(() => {
                if (this.percentDone < 99) {
                    this.percentDone += 1;
                    this.updateProgressLinear();
                }
            }, 500);

            try {
                this.downloadInProgress = true;
                this.clientInstallerState = 'Generating client ...';
                const {error, file} = await this.generateClientFile(systemId);
                if(error) {
                    throw new Error('Failed to generate client file');
                }
                const myFile = encodeURIComponent(file.path);

                const url = `downloadFileAny?file=${myFile}`;
                // the file size is about 22 MB
                this.clientInstallerState = 'Preparing download ...';
                const fileToDownload = await this.axiosDownload(url, 'application/x-msdownload');

                if (fileToDownload) {
                    const objectUrl = window.URL.createObjectURL(fileToDownload);
                    const link = document.createElement('a');
                    link.href = objectUrl;
                    link.setAttribute('download', file.name);
                    document.body.appendChild(link);
                    console.log('attribute set');
                    await delay(200);
                    link.click();
                    console.log('link clicked');
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(objectUrl); // Free up memory
                    this.alertMessage = `The client file is generated and will be downloaded shortly.`;
                } else {
                    throw new Error('fileToDownload is undefined');
                }
            } catch (error) {
                this.showSnackbar({message:'Error downloading client file. Try again or Contact engineering@dorsettcontrols.com for assistance', type: 'warning'});
            } finally {
                this.percentDone = 100;
                clearInterval(interval);
                this.isDownloading = false;
                this.downloadInProgress = false;
                this.clientInstallerState = '';
            }
        },

        handleUpdateCategories (categories) {
            if (!Array.isArray(categories)) {
                console.error('Invalid categories:', categories);
                return;
            }

            console.log('Updating categories:', categories);
            // find the system in the config
            const index = this.localSystems.findIndex(system => system.id === this.selectedSystem.id);
            if (index === -1) {
                console.warn('Could not find system in config');
                return;
            }

            // Create updated system with new categories
            const updatedSystem = mergeDeep({}, this.localSystems[index], { categories });

            // Use $set to properly update the array element
            this.$set(this.localSystems, index, updatedSystem);

            const clonedLocalSystems = mergeDeep([], this.localSystems);

            // update the store
            this.$emit('update:systems', clonedLocalSystems);
        },

        openCategories (system) {
            if(this.showCategories) {
                this.showCategories = false;
                this.selectedSystem = null;
            } else {
                this.selectedSystem = system;
                this.showCategories = true;
            }
        },

        refresh () {
            this.$emit('refresh');
            this.deleteEnabled = {};
        },

        // adds to local data only
        addSystem () {
            this.newItem = {
                ...this.defaultItem,
                id: nanoid(10),
                customerId: this.customerId
            };
            this.openEditDialog(this.newItem);
        },

        // saves all systems
        saveAllSystems () {
            this.showDataLossDialog = false;
            this.$emit('save:systems', this.localSystems);
        },

        // determines if any of the pending changes will result in data loss
        async checkForDataLoss () {
            console.log('Systems >> Checking systems:');
            await this.checkForSystemsDataLoss(this.localSystems);
            if(this.error) {
                console.log(' -- error:', this.errorMessage);
                this.showDataLossDialog = false;
                this.showSnackbar({message:'Saving systems failed. Please try again.', type: 'error'});
                return;
            }

            if(this.systemsDataLoss.length > 0) {
                this.showDataLossDialog = true;
            } else {
                console.log(' -- no loss detected');
                this.saveAllSystems();
            }
        },

        // removes a local system
        deleteSystem (id) {
            this.$set(this, 'localSystems', this.localSystems.filter(system => system.id !== id));
            this.emitSystemsUpdate();
        },

        openEditDialog (item) {
            this.editedItem = { ...item };
            if (item.id) {
                // if editing an existing item, set the start time to the correct format hh:mm
                this.editedItem.flow_plant_start_time = this.editedItem.flow_plant_start_time.split(':').slice(0, 2).join(':');
            }
            this.editDialog = true;
            this.$nextTick(() => {
                this.$refs.editForm.validate();
            });
        },

        closeEditDialog () {
            this.editDialog = false;
            this.editedItem = { ...this.defaultItem };
            this.newItem = null;
        },

        saveEdit () {
            console.log('Saving system:', this.editedItem.id);

            if (this.$refs.editForm.validate()) {
                if (this.newItem) {
                    this.$set(this.localSystems, this.localSystems.length, {...this.editedItem});
                    this.closeEditDialog();
                } else {
                    const originalSystem = this.localSystems.find(system => system.id === this.editedItem.id);
                    const changedFields = {};
                    const finalizedSystem = this.areAllCategoriesFinalized(originalSystem);

                    // timezone: wipe all weather, flow data, forecast data. forecast_info, Create new model
                    // flow_production_uom: wipe all flow data, forecast data, forecast_info. Create new model
                    // flow_storage_uom: wipe all flow data, forecast data, forecast_info. Create new model
                    // latitude: wipe all weather, forecast data. forecast_info, Create new model
                    // longitude: wipe all weather, forecast data. forecast_info, Create new model

                    // these only are impactful if the system is finalized
                    if(finalizedSystem) {
                        ['latitude', 'longitude', 'timezone'].forEach(field => {
                            if (originalSystem[field] !== this.editedItem[field]) {
                                changedFields[field] = {
                                    from: originalSystem[field],
                                    to: this.editedItem[field]
                                };
                            }

                        });

                        if (Object.keys(changedFields).length > 0) {
                            this.pendingChanges = changedFields;
                            this.warningMessage = `You are about to change critical fields: ${Object.keys(changedFields).join(', ')}.\nThese changes may affect system calculations.\nDo you want to proceed?`;
                            this.showWarningDialog = true;
                            return;
                        }
                    }
                    this.commitChanges();
                }
            }
        },

        proceedWithChanges () {
            this.showWarningDialog = false;
            this.commitChanges(true);
        },

        cancelChanges () {
            this.showWarningDialog = false;
            this.pendingChanges = null;
        },

        commitChanges (isDrastic = false) {
            const index = this.localSystems.findIndex(system => system.id === this.editedItem.id);
            if (index !== -1) {
                this.$set(this.localSystems, index, this.editedItem);
                this.emitSystemsUpdate();
            }
            this.closeEditDialog();
        },

        createModel (item) {
            console.log('Creating model for:', item.id);
            // check that there is not already a create mode task pending, in-progress, or waiting

            this.$emit('create:model', item.id);
            this.showSnackbar({message:'Model creation started. This may take a few minutes.', type: 'warning'});
        },

        formatFieldName (field) {
            const fieldMap = {
                'latitude': 'Latitude',
                'longitude': 'Longitude',
                'flow_production_uom': 'Production Unit of Measure',
                'flow_storage_uom': 'Storage Unit of Measure',
                'timezone': 'Timezone'
            };
            return fieldMap[field] || field;
        },

        emitSystemsUpdate () {
            this.$emit('update:systems', {
                systems: mergeDeep([], this.localSystems)
            });
        },

        updateProgressLinear () {
            if (this.percentDone < 50) {
                this.progressColor = 'red';
            } else if (this.percentDone < 80) {
                this.progressColor = 'orange';
            } else {
                this.progressColor = 'green';
            }
        },

        areAllCategoriesFinalized (system) {
            if(system.categories.length === 0) {
                return false;
            }
            return system.categories.every(category => category.is_finalized);
        },

        enableDelete (id) {
            this.$set(this.deleteEnabled, id, true);
        },

        goToAnalyticsHome () {
            this.$router.push('/customer/analytics');
        },

        goToHelp () {
            this.$router.push('/customer/resources');
        }
    },
    watch: {
        systems: {
            handler (newSystems){
                this.localSystems = mergeDeep([], newSystems);
                this.selectedSystem = null;
                this.showCategories = false;
            },
            deep: true,
            immediate: true
        }
    },

    mounted () {
        this.localSystems = mergeDeep([], this.systems);
        this.$root.$on('show:alert', ({message, type}) => {
            console.log('show:alert', message, type);
            const data = {message};
            if(type) {
                data.type = type;
            }

            this.showSnackbar(data);
        });
        this.deleteEnabled = {};
    },
    beforeDestroy () {
        this.$root.$off('show:alert', this.showAlert);
    },
};
</script>

<style scoped>
.align-center {
    display: flex;
    align-items: center;
}
.no-changes-text {
    margin-left: 16px;
    color: gray;
}
.no-clipping .v-label {
    white-space: nowrap;
}
.change-list {
    list-style-type: disc;
    padding-left: 20px;
    margin: 8px 0;
}
.change-details {
    color: #666;
    font-size: 0.9em;
    margin-left: 8px;
}

.v-progress-circular {
  margin: 1rem;
}

</style>
